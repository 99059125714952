
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





























































@import "src/scss/theme-default";

.tooltip {
    cursor: pointer;
    position: relative;

    &:before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &-input {
        top: 50%;
        z-index: 1;
        min-width: 310px;
        position: absolute;
        display: inline-block;
        border: 1px solid lightgray;
        border-radius: $radius-small;
        transform: translate(25px, -50%);

        &:after {
            content: "";
            top: 0;
            right: 100%;
            width: 25px;
            height: 100%;
            position: absolute;
        }

        ::v-deep {
            .input, .button {
                font-size: 0.75rem;
                font-family: $family-monospace;
            }
        }
    }
}
